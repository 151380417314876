import logo from '../logo.svg';

function Footer() {
  return (
    <div className="d-flex flex-row justify-content-between align-items-center p-2" 
      style={
      { //backgroundColor: "#282A39", 
        backgroundColor: "#1d1f2a",
        fontSize: "2vmin", 
        fontStyle: "italic",
        textShadow: "1px 1px 2px #000",
      }
     }>
     <div className="m-2">iwrotesomecode.com</div>
      <div>Powered by<img src={logo} className="App-logo" alt="logo" /></div>
    </div>
  );
}

export default Footer;