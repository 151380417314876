import TileShow from "./TileShow";

function TileList({title}) {

  const data = [
    { 
      name: "Angular Timer App",
      text: "A functioning prototype of a highly configurable timer app with repeatable sections and audio controls",
      link: "https://production.d1npkbkk4rxbw.amplifyapp.com/"
    },
    { 
      name: "Angular UI Demo", 
      text: "A simple demo containing a few possibilities for UI elements that were considered for a mobile app",
      link: "https://dev8217.d3e4vvejrh0y06.amplifyapp.com/"
    },
  ];

  const renderedTiles = data.map((item) => {
    return <TileShow key={item.name} name={item.name} text={item.text} link={item.link} />;
  });

  return (
  <div className="flex-grow-1 overflow-auto">
    <h3 className="mt-4">{title}</h3>
    <div className="mb-4">More to be added soon!</div>
    <div className="px-3 container">
      {renderedTiles}
    </div>
  </div>
  );
}

export default TileList;