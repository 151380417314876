function TileShow({name, text, link}) {
	const handleClick = (link) => {
		window.open(link, "_blank");
	}

	return (
		<div className="tile mb-3 p-3" onClick={() => {handleClick(link)}}>
			<label className="mb-2">{name}</label>
			<p className="mb-1">{text}</p>
		</div>
	);
}

export default TileShow;