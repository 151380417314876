import logo from './logo.svg';
import './App.css';
import TileList from "./components/TileList";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App d-flex flex-column">
      <TileList title="Personal Projects" />
      <Footer />
    </div>
  );
}

export default App;
